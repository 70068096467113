import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import _ from 'lodash';

export default class Api {
    protected url: string;
    protected axios: AxiosInstance;
    private config?: AxiosRequestConfig;

    constructor(url: string) {
        this.url = url;
        this.axios = this.update_config({
            baseURL: url,
            timeout: 15000,
            headers: {
                'Content-Type': "application/json"
            }
        }, true);
    }

    update_config = (config: AxiosRequestConfig, override = false) => {
        if (override) {
            //overrides the config
            this.config = config;

            return this.axios = axios.create(config);
        }

        if (!this.config) {
            //put for safe keeping ...
            this.config = config;
        }

        // const newConfig = Object.assign({}, this.config, config);
        const newConfig = _.merge(this.config, config);

        this.config = newConfig;
        return this.axios = axios.create(newConfig);
    }

    check = async () => {
        return (await this.axios.get("/login/check")).data;
    }

    login = async (username: string, password: string) => {
        return (await this.axios.post("/login", { username, password })).data;
    }

    getLanguages = async () => {
        return (await this.axios.post("/language", {})).data;
    }

    getTransaltions = async (locale: string) => {
        return (await this.axios.post("/language/translations", { locale })).data;
    }

    update = async (locale: string, srcSingularHash: string, srcPluralHash: string, payload: {
        targetVariant1: string,
        targetVariant2: string,
        targetVariant3: string,
        targetVariant4: string
    }) => {
        return (await this.axios.post("/language/translations/update", { ...payload, locale, srcPluralHash, srcSingularHash })).data;
    }

    delete = async (locale: string, srcSingular: string, srcPlural: string) => {
        return (await this.axios.post("/language/translations/delete", { locale, srcPlural, srcSingular })).data;
    }
}