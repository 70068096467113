import { observer } from "mobx-react";
import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import { RequireAuth } from "./libs/auth";
import Store from "./libs/store";
import Languages from "./pages/Languages";
import Login from "./pages/Login";
import Translations from "./pages/Translations";

@observer
export default class App extends React.Component<{ store: Store }> {
    render(): React.ReactNode {
        if (!this.props.store.perms.logged_in) {
            return <Login store={this.props.store} />;
        }

        return (
            <BrowserRouter>
                <Header />
                <Container fluid>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RequireAuth store={this.props.store}>
                                    <Navigate to="/languages" replace />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/languages"
                            element={
                                <RequireAuth store={this.props.store}>
                                    <Languages store={this.props.store} />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/languages/translations/:locale"
                            element={
                                <RequireAuth store={this.props.store}>
                                    <Translations store={this.props.store} />
                                </RequireAuth>
                            }
                        />
                    </Routes>
                </Container>
            </BrowserRouter>
        );
    }
}
