import React from "react";
import { createRoot } from 'react-dom/client';
import "normalize.css/normalize.css";
// import "./styles/main.scss";
import App from "./App";
import { toJS } from "mobx";
import consola from "./config/consola";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Api from "./libs/api";
import Store from "./libs/store";

consola().wrapConsole();

const api = new Api("/api");
const store = new Store(api);

(window as any).api = api;
(window as any).toJS = toJS;

createRoot(document.getElementById("root")!).render(<App store={store} />);
