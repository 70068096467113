

export async function timeout(timeout: number) {
    return new Promise((resolve, reject) => setTimeout(resolve, timeout));
}

export async function generateRange(): Promise<string[]> {
    const results = [];
    
    for(let i = 0 ; i <= 9 ; i++) {
        results.push(Number(i).toString())
    }

    for(let i = 'a'.charCodeAt(0) ; i <= 'z'.charCodeAt(0) ; i++) {
        results.push(String.fromCharCode(i));
    }

    return results;
}