import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Form } from "react-bootstrap";
import Store from "../../libs/store";

const plurals = ["targetVariant1", "targetVariant2", "targetVariant3", "targetVariant4"];

@observer
export default class Translator extends React.Component<{ store: Store; translation: any; onChange: (trans: any) => void }> {
    render(): React.ReactNode {
        const translators = [];
        let i = 0;
        for (const plural of this.props.translation.plurals) {
            translators.push(
                <Form.Group className="mb-3" key={plural}>
                    <Form.Label>{plural}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        onChange={action(
                            ((i: number): ((e: any) => void) => {
                                return (e) => {
                                    this.props.translation[plurals[i]] = e.target.value;
                                };
                            })(i)
                        )}
                        value={this.props.translation[plurals[i]]}
                    />
                </Form.Group>
            );
            i++;
        }

        return (
            <>
                <p>{this.props.translation.srcSingular}</p>

                {translators}
            </>
        );
    }
}
