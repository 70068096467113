import { action, makeObservable, observable } from "mobx";
import consola from "../config/consola";
import Api from "./api";

const logger = consola("store");

export default class Store {
    api: Api;

    @observable
    perms = {
        logged_in: false
    }

    constructor(api: Api) {
        this.api = api;
        
        makeObservable(this);

        this.check_login();
    }

    @action
    check_login = async () => {
        try {
            await this.api.check();

            this.perms.logged_in = true;
        } catch(e) {
            this.perms.logged_in = false;
        } 
    }

    login = async (username: string, password: string) => {
        return this.api.login(username, password);
    }

    logout = async () => {
        this.perms.logged_in = false;
    }

} 