import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import shopamine from "../images/shopmine.png";
import { Nav, Navbar, Container } from "react-bootstrap";
import { observer } from "mobx-react";

export default observer(function Header() {
    return (
        <Navbar bg="light" expand="lg">
            <Container fluid>
                <Navbar.Brand>
                    <img alt="logo" src={shopamine} width="30" height="30" />
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
});
